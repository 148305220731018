<template>
    <v-row>
        <v-col cols="12" class="d-flex justify-space-between align-center">
            <div>
                <div class="text-h5 font-weight-bold">Invoices</div>
                <small>{{ invoices.length }} en total</small>
            </div>
            <v-btn to="/create" depressed class="text-none spacing-none rounded-lg">
                <v-icon small class="mr-2">mdi-plus</v-icon>
                <span>Crear invoice</span>
            </v-btn>
        </v-col>
        <v-col cols="12" >
            <v-data-table :items="invoices" :headers="headers" :items-per-page="20" dense>
                <template v-slot:[`item.name`]="{ item }">
                    <router-link :to="`/${item.id}`">{{ item.name }}</router-link>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu offset-y rounded="lg" min-width="150">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon><v-icon small>mdi-dots-vertical</v-icon></v-btn>
                        </template>
                        <v-list dense class="my-0 py-0">
                            <v-list-item @click="deleteItem(item)">
                                <v-icon small color="red darken-1" class="mr-2">mdi-close-thick</v-icon>
                                <v-list-item-title>Eliminar</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:[`no-data`]>
                    <span></span>
                </template>
            </v-data-table>

        </v-col>
    </v-row>
</template>
<script>

export default {
    props: {
    },
    name: 'Invoices',
    data: () => ({
        loading: true,
        invoices: [],

        headers: [
            { text: '#', value: 'id', class: 'bg-gray-50'  },
            { text: 'Nombre', value: 'name', class: 'bg-gray-50 rounded-left-lg' },
            { text: 'Creado en', value: 'createdAt', class: 'bg-gray-50'  },
        ],
    }),
    mounted() {
        this.getInvoices()
    },
    methods: {
        getInvoices() {
            this.loading = true
            this.$axios.get('/invoices')
            .then((res) => {
                this.invoices = res.data.invoices
            })
            .finally(() => {
                this.loading = false
            })
        },
        deleteItem(item) {
            this.loading = true
            this.$axios.delete(`/invoices/${item.id}`)
            .then(() => {
                this.getInvoices()
            })
            .finally(() => {
                this.loading = false
            })
        }
    }
}
</script>