var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Invoices")]),_c('small',[_vm._v(_vm._s(_vm.invoices.length)+" en total")])]),_c('v-btn',{staticClass:"text-none spacing-none rounded-lg",attrs:{"to":"/create","depressed":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Crear invoice")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.invoices,"headers":_vm.headers,"items-per-page":20,"dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/" + (item.id))}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","rounded":"lg","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"my-0 py-0",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red darken-1"}},[_vm._v("mdi-close-thick")]),_c('v-list-item-title',[_vm._v("Eliminar")])],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('span')]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }